import request from '@/utils/request'

const usersApi = {
  // get users info
  users: '/api/v1/rest/users',
  signUser: '/api/v1/user',
  deleteByIdsUser: '/api/v1/rest/users/search/deleteByIds',
  findByUsernameUser: '/api/v1/rest/users/search/findByUsername',
  findByWxOpenIdUser: '/api/v1/rest/users/search/findByWxOpenId',
  notBroker: '/api/v1/rest/users/search/findNotBroker',
  queryNoBroker: '/api/v1/user/queryNoBroker'
}

/**
 * 
 * @param param { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function users(param) {
  let url = usersApi.users
  url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

/**
 * 
 * @param data
 * @returns {*}
 */
export function addUsers(data) {
  return request({
    url: usersApi.users,
    method: 'post',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function getUsers(id) {
  return request({
    url: usersApi.users + `/${id}`,
    method: 'get'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function putUsers(id, data) {
  return request({
    url: usersApi.users + `/${id}`,
    method: 'get',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function editUsers(id, data) {
  return request({
    url: usersApi.users + `/${id}`,
    method: 'patch',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function delUsers(id) {
  return request({
    url: usersApi.users + `/${id}`,
    method: 'delete'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function getUsersRoles(id) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'get'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function putUsersRoles(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function patchUsersRoles(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'patch',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function getUsersIdRoles(id, authId) {
  return request({
    url: usersApi.users + `/${id}` + '/roles' + `/${authId}`,
    method: 'get'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function delUsersIdRoles(id, authId) {
  return request({
    url: usersApi.users + `/${id}` + '/roles' + `/${authId}`,
    method: 'delete'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function delUsersRoles(id) {
  return request({
    url: usersApi.users + `/${id}` + '/roles',
    method: 'delete'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function getUsersTasks(id) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'get'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function putUsersTasks(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'get',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function patchUsersTasks(id, data) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'patch',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function delUsersTasks(id) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'delete'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function getUsersIdTasks(id, authId) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks' + `/${authId}`,
    method: 'get'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function delUsersIdTasks(id) {
  return request({
    url: usersApi.users + `/${id}` + '/tasks',
    method: 'delete'
  })
}

/**
 * special
 * @param { phone: '', username: '' }
 * @returns {*}
 */
export function signUser(param) {
  let url = usersApi.signUser
  url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}
/**
 * special
 * @param { }
 * @returns {*}
 */
export function deleteByIdsUser(param) {
  let url = usersApi.deleteByIdsUser
  url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

/**
 * special
 * @param { username: '' }
 * @returns {*}
 */
export function findByUsernameUser(param) {
  let url = usersApi.findByUsernameUser
  url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

/**
 * special
 * @param { openId: '' }
 * @returns {*}
 */
export function findByWxOpenIdUser(param) {
  let url = usersApi.findByWxOpenIdUser
  url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

/**
 * get
 * @param parameter { page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findNotBroker(param) {
  let url = usersApi.notBroker
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}

/**
 * get
 * @param parameter { page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function queryNoBroker(param) {
  let url = usersApi.queryNoBroker
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}
