<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="姓名">
                <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入姓名" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select default-value="在职" allow-clear style="width: 100%;" @change="handleChange">
                  <a-select-option value="1">
                    在职
                  </a-select-option>
                  <a-select-option value="0">
                    离职
                  </a-select-option>
                  <a-select-option value="-1">
                    全部
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="findByKeyword">查询</a-button>
                <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addRow">新建置业顾问</a-button>
        <!-- <a-button type="primary" icon="download" @click="exportReport">导出</a-button> -->
        <!-- <a-button type="primary" icon="delete" @click="deleteRow">删除</a-button> -->
      </div>

      <!-- :rowSelection="rowSelection" -->
      <my-alert :totalCount="totalCount" />
      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        showPagination="auto"
      >
        <span slot="jobStatus" slot-scope="text">
          <a-tag color="#2db7f5" v-if="text">
            在职
          </a-tag>
          <a-tag color="#f50" v-else>
            离职
          </a-tag>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="editInfo(record)">编辑</a>
            <a-divider type="vertical"></a-divider>
            <a @click="lookInfo(record)">查看</a>
          </template>
        </span>
      </s-table>

      <create-form ref="createModal" @reload="reload" />
      <row-drawer ref="createDrawer"></row-drawer>
      <delete-modal ref="deleteModal" :selectedRows="selectedRows" @reload="delReload"></delete-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, MyAlert } from '@/components'
import { queryConsultant } from '@/api/consultant'
import CreateForm from './modules/CreateUserForm'
import RowDrawer from './modules/RowDrawer'
import DeleteModal from './modules/DeleteModal'

const columns = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' }
  },
  {
    title: '在职状态',
    dataIndex: 'jobStatus',
    scopedSlots: { customRender: 'jobStatus' }
  },
  {
    title: '添加时间',
    dataIndex: 'createTime',
    sorter: true,
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'Consultant',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    RowDrawer,
    DeleteModal,
    MyAlert
  },
  data() {
    this.columns = columns
    return {
      totalCount: 0,
      noticeTitle: '后台用户',
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      avatar: [],
      // 查询参数
      queryParam: {},
      searchVisible: false,
      // 删除
      selectedRowKeys: [],
      selectedRows: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const param = {
          houseId: this.houseId
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, param)
        return queryConsultant(params).then(res => {
          const result = this.resFormat(res)
          this.totalCount = result.totalCount
          return result
        })
      }
    }
  },
  watch: {},
  created() {},
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    serial() {
      return this.$store.state.env.serial
    },
    host() {
      return this.$store.state.oss.host
    },
    houseId() {
      return this.$store.state.house.houseId
    }
  },
  methods: {
    reload() {
      this.$refs.table.refresh()
    },
    delReload() {
      this.selectedRows = []
      this.$refs.table.refresh()
    },
    addRow() {
      this.$refs.createModal.showModal()
    },
    exportReport() {
      window.location.href = `${window.location.origin}/api/v1/invite-code/export?used=true`
    },
    editInfo(record) {
      this.$refs.createModal.showModal(record)
    },
    lookInfo(record) {
      this.$refs.createDrawer.showModal(record)
    },
    deleteRow() {
      const len = this.selectedRows.length
      console.log(this.selectedRows, 'roiw')
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.$refs.deleteModal.showModal()
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleChange(value) {
      console.log(parseInt(value)) // { key: "lucy", label: "Lucy (101)" }
      this.queryParam.jobStatus = parseInt(value)
    },
    /**
     * Search
     *
     */
    findByKeyword() {
      this.searchVisible = true
      this.$refs.table.refresh()
    },
    tableReset() {
      this.searchVisible = false
      for (const key in this.queryParam) {
        this.queryParam[key] = ''
      }
      this.$refs.table.refresh()
    }
  }
}
</script>
