<template>
  <a-modal title="刪除置业顾问" :visible="visible" :confirm-loading="loading" @ok="handleOk" @cancel="handleCancel">
    <p>确定要删除这些置业顾问吗？</p>
  </a-modal>
</template>

<script>
import { delUsers } from '@/api/users'
import { asyncTime } from '@/common/word'

export default {
  props: {
      selectedRows: {
        type: Array,
        default: () => []
      }
  },
  data() {
    return {
      noticeTitle: '置业顾问管理',
      visible: false,
      loading: false
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk() {
      this.loading = true
      setTimeout(() => {
        if (this.visible) {
          this.selectedRows.forEach(item => {
            const id = item.id
            delUsers(id)
              .then(res => {
                this.handleCancel()
                this.$emit('reload')
                this.$notification.success({ message: this.noticeTitle, description: '删除成功' })
              })
              .catch(() => {
                this.loading = false
              })
          })
        }
      }, asyncTime)
    },
    handleCancel() {
      this.visible = false
      this.loading = false
    }
  }
}
</script>
