<template>
  <a-drawer
    title="置业顾问详情"
    placement="right"
    width="500px"
    class="myDescription"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="handleCancel"
  >
    <a-card :bordered="false">
      <a-spin :spinning="loading">
        <a-descriptions title="基本信息">
          <a-descriptions-item label="姓名" span="3">{{
            model ? model.name : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="手机号" span="3">{{
            model ? model.phone : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="在职状态" span="3">{{
            model ? (model.jobStatus ? '在职' : '离职') : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="创建时间" span="3">{{
            model ? model.createTime : '暂无信息'
          }}</a-descriptions-item>
        </a-descriptions>
      </a-spin>
    </a-card>
  </a-drawer>
</template>

<script>

export default {
  props: {
    media: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      noticeTitle: '置业顾问管理',
      visible: false,
      loading: false,
      id: null,
      model: null,
      form: this.$form.createForm(this)
    }
  },
  created() {},
  methods: {
    showModal(record) {
      console.log(record);
      this.model = record
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
    },
    afterVisibleChange(val) {
      console.log('visible', val)
    }
  }
}
</script>
