<template>
  <a-modal 
    title="置业顾问" 
    :width="640" 
    :visible="visible" 
    :confirmLoading="loading" 
    @ok="handleOk" 
    @cancel="handleCancel">
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="姓名">
          <a-input placeholder="请输入姓名" v-decorator="['name', { rules: [{ required: true, message: '请输入手机号' }] }]" allow-clear :maxLength="15"></a-input>
        </a-form-item>
        <a-form-item label="手机号">
          <a-input
            allow-clear
            placeholder="请输入手机号"
            v-decorator="['phone']"
            :maxLength="11"
          />
        </a-form-item>
        <a-form-item label="在职状态">
          <a-radio-group v-decorator="['jobStatus', { initialValue: true }]">
            <a-radio :value="true">
              在职
            </a-radio>
            <a-radio :value="false">
              离职
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { getConsultant, patchConsultant, addConsultant } from '@/api/consultant'

// 表单字段
const fields = [
  'name',
  'phone',
  'jobStatus'
]

export default {
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      noticeTitle: '置业顾问管理',
      visible: false,
      loading: false,
      id: null,
      model: null,
      form: this.$form.createForm(this)
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  computed: {
    houseLink() {
      const host = this.$store.state.env.host
      const houseId = this.$store.state.house.houseId
      return host + '/rest/houses/' + houseId
    }
  },
  methods: {
    officeChange(date, dateString) {
      console.log(date, dateString);
    },
    closeChange(date, dateString) {
      console.log(date, dateString);
    },
    getMedia(data) {
      this.$emit('emitAvatar', data)
    },
    validatePhone(rules, value, callback) {
      if (value.length === 0) {
        callback(new Error('请输入手机号'))
      } else {
        callback()
      }
    },
    showModal(record = null) {
      if (record) {
        const id = record.id
        this.fetchInfo(id)
      } else {
        this.visible = true
        this.model = null
      }
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          const param = { ...values }
          param.house = this.houseLink
          param.jobStatus = param.jobStatus ? 1 : 0
          console.log(param, 'param');
          if (this.model) {
            this.patchInfo(this.id, param)
          } else {
            this.newInfo(param)
          }
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
      this.form.resetFields()
    },
    fetchInfo(id) {
      this.id = id
      this.visible = true
      this.loading = true
      getConsultant(id)
        .then(res => {
          this.model = res
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    newInfo(params) {
      setTimeout(() => {
        if (this.visible) {
          addConsultant(params)
            .then(res => {
              this.handleCancel()
              this.$emit('reload')
              this.$notification.success({ message: this.noticeTitle, description: '新建成功' })
            })
            .catch(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    },
    patchInfo(id, params) {
      setTimeout(() => {
        if (this.visible) {
          patchConsultant(id, params)
            .then(res => {
              this.handleCancel()
              console.log(this.$emit('reload'), 'this.$parent');
              this.$emit('reload')
              this.$notification.success({ message: this.noticeTitle, description: '修改成功' })
            })
            .catch(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    }
  }
}
</script>
